import {useEffect} from 'react';
import {useRumClient} from '@/providers/datadog-rum';
import {error as logError} from '@/logger/client-logger';

// A special case logger where we want to send an exception directly to datadog
// used only in error boundaries & nextjs error components
// All other logs should use the regular error logger
export function useExceptionLogging(error: Error) {
  const rum = useRumClient();
  useEffect(() => {
    // it seems like we're missing these errors at the moment, so
    // using both the rum method and the error logger directly
    rum.addError(error);
    logError(error);
  }, [rum, error]);
}
