import {IconProps} from '@/ui/icon/types';
import * as React from 'react';
import type {SVGProps} from 'react';
function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" {...props}>
      <path d="M936 600h-72v-12a156.064 156.064 0 0 0-8.281-49.801A196.683 196.683 0 0 0 984 378a48.017 48.017 0 0 0-95.282-12 100.789 100.789 0 0 1-70.797 84l-24.72 7.32h-.839l-.004-37.32c0-50.922-20.227-99.758-56.234-135.77s-84.844-56.234-135.77-56.234-99.758 20.227-135.77 56.234A192.018 192.018 0 0 0 408.35 420v36.72h-.84l-24.719-7.321a100.795 100.795 0 0 1-70.801-84 48.02 48.02 0 0 0-29.016-38.258 48.029 48.029 0 0 0-47.64 6 48.025 48.025 0 0 0-18.626 44.258 196.671 196.671 0 0 0 127.563 160.8A156.062 156.062 0 0 0 335.99 588v12h-72a48.001 48.001 0 1 0 0 96h73.078a262.962 262.962 0 0 0 12.723 60 196.315 196.315 0 0 0-133.8 162.004 47.991 47.991 0 0 0 9.691 35.793 48.01 48.01 0 0 0 32.31 18.207h6a48.003 48.003 0 0 0 48-42 100.793 100.793 0 0 1 22.952-52.492 100.795 100.795 0 0 1 47.848-31.508l15.36-4.559a263.998 263.998 0 0 0 404.4 0l15.358 4.559h.004a100.795 100.795 0 0 1 70.797 84 48.003 48.003 0 0 0 47.281 42h6a48.012 48.012 0 0 0 32.31-18.207 47.99 47.99 0 0 0 9.69-35.793 196.35 196.35 0 0 0-43.401-100.41A196.332 196.332 0 0 0 850.193 756a263 263 0 0 0 12.723-60h73.078a48.001 48.001 0 1 0 0-96zM504 420a95.998 95.998 0 0 1 48-83.137 95.984 95.984 0 0 1 96 0A95.996 95.996 0 0 1 696 420v12H504zm96 420a167.977 167.977 0 0 1-118.79-49.207 167.988 167.988 0 0 1-49.207-118.79v-84a59.988 59.988 0 0 1 17.574-42.426 59.986 59.986 0 0 1 42.426-17.574h216a59.988 59.988 0 0 1 42.426 17.574 59.986 59.986 0 0 1 17.574 42.426v84a167.993 167.993 0 0 1-84 145.492 167.996 167.996 0 0 1-84 22.508z" />
    </svg>
  );
}
export function SvgMalware(props: IconProps) {
  const {size, className, ...remainingProps} = props;
  return (
    <Icon
      className={`svg-icon ${className || ''}`}
      width={size}
      height={size}
      fill="currentColor"
      data-icon="malware"
      {...remainingProps}
    />
  );
}
