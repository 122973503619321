import {SvgMalware} from '@/ui/icon/malware';
import type {NextErrorProps} from '@/types/next-error-props';
import {Button, Title, Text, Center, Paper, Stack} from '@mantine/core';

import {useExceptionLogging} from '@/hooks/use-exception-logging/use-exception-logging';

type Props = NextErrorProps & {
  renderError?: React.ReactNode; // optional override component to the default error below
};

export function PageError({error, reset, renderError}: Props) {
  useExceptionLogging(error);

  return renderError ? (
    renderError
  ) : (
    <Center>
      <Stack ta="center">
        <Center>
          <Paper withBorder>
            <SvgMalware size="40" />
          </Paper>
        </Center>
        <Title order={2}>Don&apos;t worry, your network is still intact.</Title>
        <Text size="lg">Thanks for spotting the bug blocking your path - our</Text>
        <Text size="lg" pb="xs">
          team is pulling together an extermination plan now.
        </Text>
        <div>
          <Button size="lg" color="default" variant="filled" onClick={() => reset()}>
            Continue Expanding Your Network
          </Button>
        </div>
      </Stack>
    </Center>
  );
}
